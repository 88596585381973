import {addRoute,addChildRoute, addRoutes, redirectRouteToBackend, removeRouteByName} from '@pazion/pazion-core/src/router';
import '@pazion/pazion-core/src/config';
import {getCatalogRoutes} from '@pazion/pazion-catalog/src/module-config';
import Dashboard from "./views/Dashboard.vue";
import AppOrderList from "@pazion/pazion-catalog/views/AppOrderList.vue";
import About from "./views/About.vue";

addRoutes(getCatalogRoutes());

/*
addChildRoute("settings", {
	path: "/exactonline/settings",
	name: "exactonline",
	component: About,
	meta: {
		"show-in-nav": true,
		"redirect-to-backend": true,
		roles: ["user"],
		sort: 800
	}
});

addChildRoute("settings", {
	path: "opencart/settings",
	name: "opencart",
	component: About,
	meta: {
		"show-in-nav": true,
		"redirect-to-backend": true,
		roles: ["user"],
		sort: 800
	}
});
*/

removeRouteByName('returns');
